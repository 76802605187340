import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Constants, DaysConstant } from '../../utilities/constants';
import cronstrue from 'cronstrue';

@Component({
  selector: 'app-recurrence-pattern',
  templateUrl: './recurrence-pattern.component.html',
  styleUrls: ['./recurrence-pattern.component.scss'],
})
export class RecurrencePatternComponent implements OnChanges {
  @Input() startTimeDate: Date;
  @Input() schedulerDetailsJson: any;

  daysList = Constants.DAYS_LIST;
  monthList = Constants.MONTHS_LIST;
  monthlyDaysList: any[] = [];
  monthDaysList: number[] = DaysConstant.THIRTY_ONE_DAYS;
  weekList: number[] = [];
  daysNameList = [
    {
      key: 'SUN',
      label: 'Sunday',
      isSelected: false,
    },
    {
      key: 'MON',
      label: 'Monday',
      isSelected: true,
    },
    {
      key: 'TUE',
      label: 'Tuesday',
      isSelected: false,
    },
    {
      key: 'WED',
      label: 'Wednesday',
      isSelected: false,
    },
    {
      key: 'THU',
      label: 'Thursday',
      isSelected: false,
    },
    {
      key: 'FRI',
      label: 'Friday',
      isSelected: false,
    },
    {
      key: 'SAT',
      label: 'Saturday',
      isSelected: false,
    },
  ];

  hourlyList = [
    { key: 0, label: 'Once' },
    { key: 1, label: 'Every 1 hour' },
    { key: 2, label: 'Every 2 hour' },
    { key: 3, label: 'Every 3 hour' },
    { key: 4, label: 'Every 4 hour' },
    { key: 5, label: 'Every 5 hour' },
    { key: 6, label: 'Every 6 hour' },
    { key: 7, label: 'Every 7 hour' },
    { key: 8, label: 'Every 8 hour' },
  ];

  weekDayNameList = Constants.DAYS_NAME_LIST;
  weekDayList = Constants.WEEK_NUM_LIST;
  tabList = Constants.TAB_NAMES;
  monthNameList = Constants.MONTHS_NAME_LIST;

  tabMap = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
  };

  dailyOptionMap = {
    EVERY_N_DAY: 'EVERY_N_DAY',
    EVERYDAY: 'EVERYDAY',
  };

  monthlyOptionMap = {
    DAY: 'DAY',
    WEEKDAY: 'WEEKDAY',
  };

  yearlyOptionMap = {
    DAY: 'DAY',
    WEEKDAY: 'WEEKDAY',
  };

  activeTab = this.tabList[0];
  selectedDailyTab = this.dailyOptionMap.EVERY_N_DAY;
  selectedMonthlyTab = this.monthlyOptionMap.DAY;
  selectedYearlyTab = this.yearlyOptionMap.DAY;
  selectedDayInterval: number;
  selectedHourInterval: number;
  endByHourlyList: { label: string; key: number }[] = [];
  endByHour: number;
  selectedWeeklyRecurInterval: number;
  selectedYearlyRecurInterval: number;
  cronExpression: string;
  cronDescription: string;
  schedulerDetailJSON: any;
  secondsMinutesHoursStr = '0 0 10';

  monthlyDayCriteria = {
    day: [1],
  };

  monthlyWeekDayCriteria = {
    week: '#1',
    day: 1,
  };

  yearlyDayCriteria = {
    month: 1,
    day: 1,
  };

  yearlyWeekDayCriteria = {
    week: '#1',
    day: 'MON',
    month: 1,
  };

  MONTH_DAYS_MAP: any = {
    1: DaysConstant.THIRTY_ONE_DAYS,
    2: DaysConstant.TWENTY_NINE_DAYS,
    3: DaysConstant.THIRTY_ONE_DAYS,
    4: DaysConstant.THIRTY_DAYS,
    5: DaysConstant.THIRTY_ONE_DAYS,
    6: DaysConstant.THIRTY_DAYS,
    7: DaysConstant.THIRTY_ONE_DAYS,
    8: DaysConstant.THIRTY_ONE_DAYS,
    9: DaysConstant.THIRTY_DAYS,
    10: DaysConstant.THIRTY_ONE_DAYS,
    11: DaysConstant.THIRTY_DAYS,
    12: DaysConstant.THIRTY_ONE_DAYS,
  };

  constructor() {
    for (let i = 1; i <= 52; i++) {
      this.weekList.push(i);
    }
    this.monthlyDaysList = this.MONTH_DAYS_MAP[1];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.startTimeDate) {
      this.prepareHourList();
      this.secondsMinutesHoursStr = `0 ${this.startTimeDate.getUTCMinutes()} ${this.startTimeDate.getUTCHours()}`;
    }
    if (changes['schedulerDetailsJson']?.currentValue !== undefined) {
      this.setRecurrencePattern();
    }
  }

  setRecurrencePattern() {
    this.activeTab = this.tabList.find(
      (elem) => elem.id === this.schedulerDetailsJson.type
    ) as { id: string; label: string };
    switch (this.activeTab.id) {
      case 'Daily':
        this.selectedDailyTab = this.schedulerDetailsJson.subType;
        if (this.selectedDailyTab === this.dailyOptionMap.EVERY_N_DAY) {
          this.selectedDayInterval = this.schedulerDetailsJson.criteria;
        } else if (this.selectedDailyTab === this.dailyOptionMap.EVERYDAY) {
          if (this.schedulerDetailsJson.criteria?.hourInterval) {
            this.selectedHourInterval =
              this.schedulerDetailsJson.criteria.hourInterval;
            this.endByHour = this.schedulerDetailsJson.criteria.endByHour;
          } else {
            this.selectedHourInterval = 0;
          }
        }
        break;
      case 'Weekly':
        this.daysNameList.forEach((day) => {
          day.isSelected = false;
          if (this.schedulerDetailsJson.criteria?.includes(day.key)) {
            day.isSelected = true;
          }
        });
        break;
      case 'Monthly':
        this.selectedMonthlyTab = this.schedulerDetailsJson.subType;
        if (this.selectedMonthlyTab === this.monthlyOptionMap.DAY) {
          this.monthlyDayCriteria = this.schedulerDetailsJson.criteria;
        } else if (this.selectedMonthlyTab === this.monthlyOptionMap.WEEKDAY) {
          this.monthlyWeekDayCriteria = this.schedulerDetailsJson.criteria;
        }
        break;
      case 'Yearly':
        this.selectedYearlyTab = this.schedulerDetailsJson.subType;
        if (this.selectedYearlyTab === this.yearlyOptionMap.DAY) {
          this.yearlyDayCriteria = this.schedulerDetailsJson.criteria;
        } else if (this.selectedYearlyTab === this.yearlyOptionMap.WEEKDAY) {
          this.yearlyWeekDayCriteria = this.schedulerDetailsJson.criteria;
        }
        break;
      default:
        break;
    }
  }

  onDateClick(day: number) {
    const index = this.monthlyDayCriteria?.day.indexOf(day);
    if (
      (this.monthlyDayCriteria?.day?.length === 1 && index === -1) ||
      this.monthlyDayCriteria?.day?.length > 1
    ) {
      if (index >= 0) {
        this.monthlyDayCriteria.day.splice(index, 1);
      } else {
        this.monthlyDayCriteria.day.push(day);
      }
    }
  }

  onActiveItemChange(event: any): void {
    this.activeTab = event;
  }

  onWeekDaySelect(dayName: {
    key: string;
    label: string;
    isSelected: boolean;
  }) {
    if (this.daysNameList.filter((day) => day.isSelected)?.length === 0) {
      dayName.isSelected = true;
    }
  }

  createDailyCron(): void {
    if (this.selectedDailyTab === this.dailyOptionMap.EVERY_N_DAY) {
      this.cronExpression = ` */${this.selectedDayInterval} * ?`;
      this.getSchedulerDetailJSON(
        this.selectedDailyTab,
        this.selectedDayInterval
      );
    } else if (this.selectedDailyTab === this.dailyOptionMap.EVERYDAY) {
      this.cronExpression = ` * * ?`;
      let criteria: any = {
        hourInterval: this.selectedHourInterval,
      };
      if (this.selectedHourInterval !== 0) {
        criteria.endByHour = this.endByHour;
      }
      this.getSchedulerDetailJSON(this.selectedDailyTab, criteria);
    }
  }

  createWeeklyCron(): void {
    const selectedDays: string[] = [];

    if (this.daysNameList.length) {
      for (const day of this.daysNameList) {
        if (day.isSelected) {
          selectedDays.push(day.key);
        }
      }
      if (selectedDays.length !== 7 && selectedDays.length !== 0) {
        this.cronExpression = ` ? * ${selectedDays.join()}`;
      } else {
        this.cronExpression = ` * * ?`;
      }
    }
    this.getSchedulerDetailJSON('', selectedDays);
  }

  createMonthlyCron(): void {
    if (this.selectedMonthlyTab === this.monthlyOptionMap.DAY) {
      this.cronExpression = ` ${this.monthlyDayCriteria.day.toString()} * ?`;
      this.getSchedulerDetailJSON(
        this.selectedMonthlyTab,
        this.monthlyDayCriteria
      );
    } else if (this.selectedMonthlyTab === this.monthlyOptionMap.WEEKDAY) {
      this.cronExpression = ` ? * ${this.monthlyWeekDayCriteria.day}${this.monthlyWeekDayCriteria.week}`;
      this.getSchedulerDetailJSON(
        this.selectedMonthlyTab,
        this.monthlyWeekDayCriteria
      );
    }
  }

  createYearlyCron(): void {
    if (this.selectedYearlyTab === this.yearlyOptionMap.DAY) {
      this.cronExpression = ` ${this.yearlyDayCriteria.day} ${this.yearlyDayCriteria.month} ?`;
      this.getSchedulerDetailJSON(
        this.selectedYearlyTab,
        this.yearlyDayCriteria
      );
    } else if (this.selectedYearlyTab === this.yearlyOptionMap.WEEKDAY) {
      this.cronExpression = ` ? ${this.yearlyWeekDayCriteria.month} ${this.yearlyWeekDayCriteria.day}${this.yearlyWeekDayCriteria.week}`;
      this.getSchedulerDetailJSON(
        this.selectedYearlyTab,
        this.yearlyWeekDayCriteria
      );
    }
  }

  getSchedulerDetailJSON(subType: string, criteria: any) {
    this.schedulerDetailJSON = {
      type: this.activeTab.id,
      subType,
      criteria,
    };
  }

  getCronExpressionAndDescription() {
    switch (this.activeTab.id) {
      case this.tabMap.DAILY:
        this.createDailyCron();
        break;
      case this.tabMap.WEEKLY:
        this.createWeeklyCron();
        break;
      case this.tabMap.MONTHLY:
        this.createMonthlyCron();
        break;
      case this.tabMap.YEARLY:
        this.createYearlyCron();
        break;
      default:
        break;
    }
    let cronExpression = `${this.secondsMinutesHoursStr}${this.cronExpression}`;
    let cronDes = `0 ${this.startTimeDate.getMinutes()} ${this.startTimeDate.getHours()}${
      this.cronExpression
    }`;

    if (
      this.activeTab.id === this.tabMap.DAILY &&
      this.selectedDailyTab === this.dailyOptionMap.EVERYDAY
    ) {
      if (this.selectedHourInterval !== 0) {
        cronExpression = `0 ${this.startTimeDate.getUTCMinutes()} ${this.startTimeDate.getUTCHours()}-${
          this.endByHour - 5
        }/${this.selectedHourInterval}${this.cronExpression}`;

        cronDes = `0 ${this.startTimeDate.getMinutes()} ${this.startTimeDate.getHours()}-${
          this.endByHour
        }/${this.selectedHourInterval}${this.cronExpression}`;
      }
    }
    if (this.cronExpression) {
      this.cronDescription = cronstrue.toString(cronDes);
    }
    return {
      cronExpression,
      cronDescription: this.cronDescription,
      frequency: this.activeTab.id,
      schedulerDetailJSON: this.schedulerDetailJSON,
    };
  }

  onMonthChange() {
    if (this.yearlyDayCriteria.month) {
      const monthNum = this.yearlyDayCriteria.month;
      this.monthlyDaysList = this.MONTH_DAYS_MAP[monthNum];
      this.yearlyDayCriteria.day = 1;
    }
  }

  prepareHourList() {
    this.endByHourlyList = [];
    if (this.startTimeDate) {
      const hour = this.startTimeDate.getHours();
      for (let i = hour + 1; i <= 17; i++) {
        this.endByHourlyList.push({ label: `${i}:00`, key: i });
      }
      if (hour < 17) {
        this.endByHour = hour + 1;
      } else {
        this.endByHourlyList = [{ label: `17:00`, key: 17 }];
        this.endByHour = 17;
      }
    }
  }
}
