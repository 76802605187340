<div
  class="template-content-container"
  [ngClass]="{
    regular: !formData.isCarousel,
    'box-height': formData.buttonRequests?.length,
    'box-bottom':
      formData.isButton &&
      (!formData.isMedia ||
        (formData.isMedia && formData.mediaType === mediaType.DOC))
  }"
  *ngIf="formData"
>
  <div
    class="main"
    [ngClass]="{
      'text-center d-flex': formData.orientation === orientation.HORIZONTAL,
      'flex-row':
        formData.orientation === orientation.HORIZONTAL &&
        formData.layout === rcsLayout.LEFT,
      'flex-row-reverse':
        formData.orientation === orientation.HORIZONTAL &&
        formData.layout === rcsLayout.RIGHT
    }"
    *ngIf="
      !isCarousel &&
      (formData.orientation === orientation.HORIZONTAL ||
        formData.orientation === orientation.VERTICAL)
    "
  >
    <div
      class="media d-flex align-items-center"
      [ngClass]="{
      'media-layout': formData.orientation === orientation.HORIZONTAL,
      'text-center': formData.orientation === orientation.VERTICAL,
    }"
    >
      <img
        class="preview-media image"
        [src]="formData.mediaUrl"
        *ngIf="
          formData.mediaType === mediaType.IMAGE &&
          formData.mediaUrl &&
          !formData.mediaUrl?.includes('#mediaUrl1#')
        "
        alt="preview"
      />
      <video
        controls
        controlsList="nodownload"
        class="preview-media video"
        *ngIf="
          isCalledFromCreation &&
          formData.mediaType === mediaType.VIDEO &&
          formData.mediaUrl &&
          !formData.mediaUrl?.includes('#mediaUrl1#') &&
          (!formData.videoThumbnail?.length ||
            formData.videoThumbnail?.includes('#thumbnail1#')) &&
          !formData.staticThumbnailUrl?.length
        "
      >
        <source [src]="formData.mediaUrl" type="video/mp4" />
        <track kind="subtitles" src="" srclang="en" label="English subtitles" />
      </video>
      <i
        class="bi bi-play-circle thumbnail-icon"
        *ngIf="
          (!isCalledFromCreation &&
            formData.videoThumbnail &&
            !formData.videoThumbnail?.includes('#thumbnail1#')) ||
          (formData.mediaType === mediaType.VIDEO &&
            !formData.videoThumbnail?.includes('#thumbnail1#') &&
            !formData.staticThumbnailUrl?.length &&
            formData.videoThumbnail.length)
        "
      ></i>
      <img
        class="preview-media image"
        [src]="formData.videoThumbnail"
        *ngIf="
          formData.mediaType === mediaType.VIDEO &&
          !formData.videoThumbnail?.includes('#thumbnail1#') &&
          !formData.staticThumbnailUrl?.length &&
          formData.videoThumbnail.length
        "
        alt="thumbnail preview"
      />
      <div
        class="dynamic-thumbnail d-flex align-items-center justify-content-center"
        [ngClass]="{
          'horizontal-media': formData.orientation === orientation.HORIZONTAL
        }"
        *ngIf="
          !isCalledFromCreation &&
          formData.videoThumbnail?.includes('#thumbnail1#')
        "
      >
        <p class="thumbnail-text">Dynamic Thumbnail</p>
      </div>
      <div
        class="dynamic-thumbnail d-flex align-items-center justify-content-center"
        [ngClass]="{
          'horizontal-media': formData.orientation === orientation.HORIZONTAL
        }"
        *ngIf="
          isCalledFromCreation &&
          formData.mediaType === mediaType.VIDEO &&
          (!formData.videoThumbnail?.length ||
            formData.videoThumbnail?.includes('#thumbnail1#')) &&
          formData.staticThumbnailUrl?.length
        "
      >
        <p class="thumbnail-text">Dynamic Thumbnail</p>
      </div>
      <div
        class="dynamic-thumbnail d-flex align-items-center justify-content-center"
        [ngClass]="{
          'horizontal-media': formData.orientation === orientation.HORIZONTAL
        }"
        *ngIf="
          isCalledFromCreation &&
          (!formData.mediaUrl || formData.mediaUrl?.includes('#mediaUrl1#')) &&
          !formData.videoThumbnail?.length &&
          !formData.staticThumbnailUrl?.length &&
          formData.staticUrl?.length
        "
      >
        <p class="thumbnail-text">Dynamic Media</p>
      </div>
      <div
        class="dynamic-thumbnail d-flex align-items-center justify-content-center"
        [ngClass]="{
          'horizontal-media': formData.orientation === orientation.HORIZONTAL
        }"
        *ngIf="
          !isCalledFromCreation &&
          formData.mediaUrl?.includes('#mediaUrl1#') &&
          !formData.videoThumbnail?.length
        "
      >
        <p class="thumbnail-text">Dynamic Media</p>
      </div>
    </div>
    <div
      class="horizontal-content-buttons"
      *ngIf="formData.orientation === orientation.HORIZONTAL"
    >
      <ng-container *ngTemplateOutlet="contentAndButtons"></ng-container>
    </div>
    <div
      class="vertical-content-buttons"
      *ngIf="formData.orientation === orientation.VERTICAL"
    >
      <ng-container *ngTemplateOutlet="contentAndButtons"></ng-container>
    </div>
    <ng-template #contentAndButtons>
      <div class="content">
        <p class="title" *ngIf="formData.cardTitle">
          <span title="{{ formData.cardTitle }}">{{
            formData.cardTitle.length >
            (formData.orientation === orientation.HORIZONTAL ? 17 : 25)
              ? formData.cardTitle.slice(
                  0,
                  formData.orientation === orientation.HORIZONTAL ? 17 : 25
                ) + "... "
              : formData.cardTitle
          }}</span>
        </p>
        <p class="content" *ngIf="content">
          <span
            *ngIf="
              !isTextExpanded && formData.orientation === orientation.HORIZONTAL
            "
            title="{{ content }}"
            >{{
              content.length > 60 ? content.slice(0, 60) + "... " : content
            }}</span
          >
          <span
            *ngIf="
              !isTextExpanded && formData.orientation === orientation.VERTICAL
            "
            >{{
              content.length > maxLetterCount
                ? content.slice(0, maxLetterCount) + "... "
                : content
            }}</span
          >
          <span *ngIf="isTextExpanded">{{ content }} </span>
          <p-button
            *ngIf="
              content.length > maxLetterCount &&
              formData.orientation === orientation.VERTICAL
            "
            styleClass="p-button-link p-0 expand-text-btn"
            label="{{ isTextExpanded ? 'Read less' : 'Read more' }}"
            (click)="toggleText()"
            (keydown)="$event.preventDefault()"
          >
          </p-button>
        </p>
      </div>
      <div
        *ngIf="
          ctaButtons?.length &&
          formData.isMedia &&
          formData.mediaType !== mediaType.DOC
        "
        class="cta-buttons"
      >
        <ng-container *ngFor="let button of ctaButtons; let i = index">
          <div
            class="d-flex align-items-center justify-content-center gap-1"
            [ngClass]="{
                'cta-button-1': i === 0,
                'cta-button-2': i === 1,
                'cta-button-3': i === 2,
                'cta-button-4': i === 3,
              }"
            *ngIf="button.buttonLabel && i < 4"
          >
            <span *ngIf="button.buttonAction === buttonActionType.LOCATION">
              <i class="bi bi-geo-alt-fill"></i>
            </span>
            <span
              *ngIf="
                button.buttonAction === buttonActionType.STATIC_CALLBACK ||
                button.buttonAction === buttonActionType.DYNAMIC_CALLBACK ||
                button.buttonAction === buttonActionType.CALL_BACK
              "
            >
              <i class="bi bi-telephone-fill"></i>
            </span>
            <span
              *ngIf="
                button.buttonAction === buttonActionType.STATIC_CTA ||
                button.buttonAction === buttonActionType.DYNAMIC_CTA ||
                button.buttonAction === buttonActionType.WEB_FORM_URL
              "
            >
              <i class="bi bi-globe"></i>
            </span>
            <span *ngIf="button.buttonAction === buttonActionType.CALENDAR">
              <i class="bi bi-calendar4-range"></i>
            </span>
            <span
              title="{{ button.buttonLabel }}"
              *ngIf="formData.orientation === orientation.HORIZONTAL"
              >{{
                button.buttonLabel.length > 10
                  ? button.buttonLabel.slice(0, 10) + "... "
                  : button.buttonLabel
              }}</span
            >
            <span *ngIf="formData.orientation === orientation.VERTICAL">{{
              button.buttonLabel
            }}</span>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
  <div
    class="main doc-layout"
    *ngIf="
      !isCarousel &&
      (!formData.isMedia ||
        (formData.isMedia && formData.mediaType === mediaType.DOC))
    "
  >
    <div
      class="d-flex align-items-center justify-content-center gap-3 my-2"
      *ngIf="
        formData.mediaUrl &&
        !formData.mediaUrl?.includes('#mediaUrl1#') &&
        mediaFileType === mediaType.DOC &&
        formData.orientation === orientation.BOTTOM
      "
    >
      <img src="{{ icons.PDF_ICON }}" alt="pdf icon" />
      <span class="filename" title="{{ pdfFileName }}">{{
        pdfFileName.length > 15
          ? pdfFileName.slice(0, 15) + "... "
          : pdfFileName
      }}</span>
    </div>
    <div class="content">
      <p class="content" *ngIf="content">
        <span *ngIf="!isTextExpanded">{{
          content.length >
          (!formData.isMedia && formData.buttonRequests?.length
            ? 500
            : !formData.isMedia && !formData.buttonRequests?.length
            ? 530
            : 415)
            ? content.slice(
                0,
                !formData.isMedia && formData.buttonRequests?.length
                  ? 500
                  : !formData.isMedia && !formData.buttonRequests?.length
                  ? 530
                  : 415
              ) + "... "
            : content
        }}</span>
        <span *ngIf="isTextExpanded">{{ content }} </span>
        <p-button
          *ngIf="
            content.length >
            (!formData.isMedia && formData.buttonRequests?.length
              ? 500
              : !formData.isMedia && !formData.buttonRequests?.length
              ? 530
              : 415)
          "
          styleClass="p-button-link p-0 expand-text-btn"
          label="{{ isTextExpanded ? 'Read less' : 'Read more' }}"
          (click)="toggleText()"
          (keydown)="$event.preventDefault()"
        >
        </p-button>
      </p>
    </div>
    <div
      class="d-flex align-items-center justify-content-center gap-3 my-2"
      *ngIf="
        formData.mediaUrl &&
        !formData.mediaUrl?.includes('#mediaUrl1#') &&
        mediaFileType === mediaType.DOC &&
        formData.orientation === orientation.TOP
      "
    >
      <img src="{{ icons.PDF_ICON }}" alt="pdf icon" />
      <span class="filename" title="{{ pdfFileName }}">{{
        pdfFileName.length > 15
          ? pdfFileName.slice(0, 15) + "... "
          : pdfFileName
      }}</span>
    </div>
  </div>
  <div class="carousel" *ngIf="isCarousel">
    <ng-container *ngFor="let section of carouselUICards; let i = index">
      <div class="main">
        <div
          class="media"
          *ngIf="section.mediaURL && !section.mediaURL?.includes('#mediaUrl')"
        >
          <img
            class="preview-media image"
            [src]="section.mediaURL"
            *ngIf="
              section.mediaType?.toLowerCase() ===
              mediaType.IMAGE?.toLowerCase()
            "
            alt="preview"
          />

          <video
            controls
            controlsList="nodownload"
            class="preview-media video"
            *ngIf="
              section.carouselMediaType?.toLowerCase() ===
                mediaType.VIDEO?.toLowerCase() &&
              section.mediaURL &&
              (!section.videoThumbnail ||
                section.videoThumbnail?.includes('#thumbnail')) &&
              !section.staticThumbnailUrl
            "
          >
            <source [src]="section.mediaURL" type="video/mp4" />
            <track
              kind="subtitles"
              src=""
              srclang="en"
              label="English subtitles"
            />
          </video>
        </div>
        <div class="media">
          <img
            class="preview-media image"
            [src]="section.videoThumbnail"
            *ngIf="
              section.carouselMediaType?.toLowerCase() ===
                mediaType.VIDEO?.toLowerCase() &&
              section.videoThumbnail &&
              !section.videoThumbnail?.includes('#thumbnail') &&
              (!section.staticThumbnailUrl ||
                section.staticThumbnailUrl?.length === 0)
            "
            alt="thumbnail preview"
          />
          <i
            class="bi bi-play-circle thumbnail-icon"
            *ngIf="
              (!isCalledFromCreation &&
                !section.videoThumbnail?.includes('#thumbnail')) ||
              (section.carouselMediaType?.toLowerCase() ===
                mediaType.VIDEO?.toLowerCase() &&
                !section.videoThumbnail?.includes('#thumbnail') &&
                !section.staticThumbnailUrl?.length &&
                section.videoThumbnail.length)
            "
          ></i>
        </div>

        <div
          class="dynamic-thumbnail d-flex align-items-center justify-content-center"
          *ngIf="
            section.carouselMediaType?.toLowerCase() ===
              mediaType.VIDEO?.toLowerCase() &&
            (!section.videoThumbnail?.length ||
              section.videoThumbnail?.includes('#thumbnail')) &&
            section.staticThumbnailUrl?.length
          "
        >
          <p class="thumbnail-text">Dynamic Thumbnail</p>
        </div>

        <div
          *ngIf="
            (!section.mediaURL || section.mediaURL?.includes('#mediaUrl')) &&
            (!section.videoThumbnail || section.videoThumbnail?.length === 0) &&
            !section.staticThumbnailUrl?.length &&
            section.staticUrl?.length
          "
          class="dynamic-thumbnail d-flex align-items-center justify-content-center"
        >
          <p class="thumbnail-text">Dynamic Media</p>
        </div>

        <div class="content">
          <p class="title" *ngIf="section.cardTitle">
            <span>
              {{
                section.cardTitle.length > 25
                  ? section.cardTitle.slice(0, 25) + "... "
                  : section.cardTitle
              }}
            </span>
          </p>
          <p class="content" *ngIf="section.content">
            <span [title]="section.content">
              {{
                section.content.length > 62
                  ? section.content.slice(0, 62) + "..."
                  : section.content
              }}
            </span>
          </p>
        </div>

        <div
          *ngIf="section.buttons?.length && section.mediaType !== mediaType.DOC"
          class="cta-buttons"
        >
          <ng-container *ngFor="let button of section.buttons; let j = index">
            <div
              class="d-flex align-items-center justify-content-center gap-1"
              [ngClass]="{
                'cta-button-1': j === 0,
                'cta-button-2': j === 1,
                'cta-button-3': j === 2,
                'cta-button-4': j === 3
              }"
              *ngIf="button.label && j < 4"
            >
              <span
                *ngIf="
                  button.buttonAction?.toLowerCase() ===
                  buttonActionValue.LOCATION?.toLowerCase()
                "
              >
                <i class="bi bi-geo-alt-fill"></i>
              </span>
              <span
                *ngIf="
                  button.buttonAction?.toLowerCase() ===
                    buttonActionValue.STATIC_CALLBACK?.toLowerCase() ||
                  button.buttonAction?.toLowerCase() ===
                    buttonActionValue.DYNAMIC_CALLBACK?.toLowerCase()
                "
              >
                <i class="bi bi-telephone-fill"></i>
              </span>
              <span
                *ngIf="
                  button.buttonAction?.toLowerCase() ===
                    buttonActionValue.STATIC_CTA?.toLowerCase() ||
                  button.buttonAction?.toLowerCase() ===
                    buttonActionValue.DYNAMIC_CTA?.toLowerCase() ||
                  button.buttonAction?.toLowerCase() ===
                    buttonActionValue.WEB_FORM_URL?.toLowerCase()
                "
              >
                <i class="bi bi-globe"></i>
              </span>
              <span
                *ngIf="
                  button.buttonAction?.replaceAll(' ', '_')?.toLowerCase() ===
                  buttonActionType.CALL_BACK?.toLowerCase()
                "
              >
                <i class="bi bi-telephone-fill"></i>
              </span>
              <span
                *ngIf="
                  button.buttonAction?.toLowerCase() ===
                  buttonActionValue.CALENDAR?.toLowerCase()
                "
              >
                <i class="bi bi-calendar4-range"></i>
              </span>
              <span title="{{ button.label }}"
                >{{
                  button.label?.length > 15
                    ? button.label.slice(0, 15) + "..."
                    : button.label
                }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="template-preview-cta-container">
  <div class="main-cta-container">
    <div
      *ngIf="
        formData.isButton &&
        (!formData.isMedia ||
          (formData.isMedia && formData.mediaType === mediaType.DOC))
      "
      class="cta-buttons"
    >
      <ng-container *ngFor="let button of ctaButtons">
        <div class="user-button gap-2" *ngIf="button.buttonLabel">
          <span *ngIf="button.buttonAction === buttonActionType.LOCATION">
            <i class="bi bi-geo-alt-fill"></i>
          </span>
          <span
            *ngIf="
              button.buttonAction === buttonActionType.STATIC_CALLBACK ||
              button.buttonAction === buttonActionType.DYNAMIC_CALLBACK ||
              button.buttonAction === buttonActionType.CALL_BACK
            "
          >
            <i class="bi bi-telephone-fill"></i>
          </span>
          <span
            *ngIf="
              button.buttonAction === buttonActionType.STATIC_CTA ||
              button.buttonAction === buttonActionType.DYNAMIC_CTA ||
              button.buttonAction === buttonActionType.WEB_FORM_URL
            "
          >
            <i class="bi bi-globe"></i>
          </span>
          <span *ngIf="button.buttonAction === buttonActionType.CALENDAR">
            <i class="bi bi-calendar4-range"></i>
          </span>
          <span title="{{ button.buttonLabel }}">{{
            button.buttonLabel?.length > 8
              ? button.buttonLabel.slice(0, 8) + "... "
              : button.buttonLabel
          }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
