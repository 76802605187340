<div class="modal-header">
  <span class="header-text">
    <h4 class="modal-title pull-left">{{ title }}</h4>
  </span>
  <span
    class="close-icon"
    (click)="onCancelClick(true)"
    (keydown)="$event.preventDefault()"
  >
    &times;
  </span>
</div>
<div class="modal-content">
  <div *ngIf="!showFilePicker">
    <div class="row">
      <div class="col-md-12">
        <p class="form-label">Filename</p>
        <div class="card flex flex-wrap justify-content-center gap-3">
          <span class="p-input-icon-right">
            <img
              class="copy-icon cursor-pointer"
              src="assets/svg/form-listing/copy-form-link.svg"
              (click)="copyLink('FILENAME')"
              alt="copy-file-name"
            />
            <input
              type="text"
              pInputText
              class="form-control"
              [disabled]="true"
              [value]="fileDetails.fileName"
              pTooltip="{{
                fileDetails.fileName.length > 48 ? fileDetails.fileName : ''
              }}"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <p class="form-label">Path</p>
        <div class="card flex flex-wrap justify-content-center gap-3">
          <span class="p-input-icon-right">
            <img
              class="copy-icon cursor-pointer"
              src="assets/svg/form-listing/copy-form-link.svg"
              (click)="copyLink('PATH')"
              alt="copy-file-path"
            />
            <input
              type="text"
              pInputText
              class="form-control"
              [disabled]="true"
              [value]="fileDetails.filePath"
              pTooltip="{{
                fileDetails.filePath.length > 48 ? fileDetails.filePath : ''
              }}"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2">
        <div class="step mt-2">Step 1</div>
      </div>
      <div class="col-md-10">
        <span
          >Create and upload sample CSV file with above filename (max file size
          1MB) which has column names and column sequence same as final segment
          file.</span
        >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <div class="step mt-2">Step 2</div>
      </div>
      <div class="col-md-10">
        <span
          >Place final data file with above mentioned filename on displayed
          folder path, for campaign execution.</span
        >
      </div>
    </div>
  </div>
  <div class="mt-2" *ngIf="showFilePicker">
    <app-file-picker
      [allowedExtensions]="allowedExtensions"
      [fileMaxSize]="fileMaxSize"
      [allowedMimeTypes]="allowedMimeTypes"
      (selectedFiles)="onSelectedFiles($event)"
    ></app-file-picker>
  </div>
</div>
<div class="modal-footer mt-4">
  <button
    *ngIf="showCancelButton"
    type="button"
    class="btn btn-footer me-2"
    (click)="onCancelClick()"
  >
    {{ cancelButtonText }}
  </button>
  <button
    *ngIf="!showFilePicker"
    type="button"
    class="btn btn-footer"
    (click)="setUploadFileConfig()"
  >
    Upload Sample File
  </button>
  <button
    *ngIf="showFilePicker"
    type="button"
    class="btn btn-footer"
    (click)="onConfirmClick()"
    [disabled]="!enableMapPlaceholder"
  >
    Map Placeholders
  </button>
</div>
