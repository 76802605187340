<div class="preview-container">
  <div class="preview-body">
    <div *ngIf="loading">
      <ngx-spinner size="medium" type="ball-clip-rotate"> </ngx-spinner>
    </div>
    <iframe
      class="iframe"
      title="preview-form"
      [src]="urlSafe"
      (load)="onIframeLoad()"
      id="previewForm"
      name="preview-form"
      #previewFormFrame
    ></iframe>
  </div>
  <div class="footer d-flex justify-content-end mt-2 gap-2">
    <p-button
      label="Back to Forms"
      styleClass="p-button-outlined p-button-rounded "
      (click)="onClose()"
      (KeyDown)="$event.preventDefault()"
    ></p-button>

    <p-button
      *ngIf="isPublishView"
      label="Publish"
      styleClass="p-button-filled p-button-rounded"
      (click)="confirmationPopup()"
      (KeyDown)="$event.preventDefault()"
    ></p-button>
  </div>
</div>
<p-confirmDialog #cd [style]="{ width: '40vw' }">
  <ng-template pTemplate="header">
    <div class="modal-header">
      <i class="bi bi-exclamation-triangle"></i>
      <h4 class="modal-title pull-left">Confirm Action!</h4>
      <button
        type="button"
        class="btn-close ms-auto"
        aria-label="Close"
        (click)="cd.reject()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        label="No"
        styleClass="p-button-outlined p-button-rounded no-btn"
        (click)="backToQuestionsList()"
        (KeyDown)="$event.preventDefault()"
      >
      </p-button>

      <p-button
        label="Yes"
        styleClass="p-button-filled p-button-rounded yes-btn"
        (click)="onClose('publish')"
        (KeyDown)="$event.preventDefault()"
      >
      </p-button>
    </div>
  </ng-template>
</p-confirmDialog>
