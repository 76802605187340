import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiConfig, GridConfig, GridHeader } from './common-grid.interface';
import {
  ButtonType,
  GRID_DATA_TYPE,
  GridConstants,
  METHOD_TYPE,
} from './common-grid.constant';
import { GridService } from './common-grid.service';
import { LazyLoadEvent, SortEvent } from 'primeng/api';
import { FilterTablePipe } from 'src/app/modules/file/pipe/filter-table.pipe';
import { Table } from 'primeng/table';
import {
  GRID_NAME,
  matchModeOptionsForFilter,
} from 'src/app/core/constants/common';
import { CommonService } from '../../services/common.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-common-grid',
  templateUrl: './common-grid.component.html',
  styleUrls: ['./common-grid.component.scss'],
})
export class CommonGridComponent implements OnChanges {
  @Input() gridName: string;
  @Input() apiConfig: ApiConfig;
  @Input() headers: Array<GridHeader> = [];
  @Input() gridConfig: GridConfig = {
    pageSizeOptions: [10, 20, 30],
  };
  @ViewChild('commonTable') commonTable: Table;

  @Output() onButtonClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() onCellClickEvent: EventEmitter<any> = new EventEmitter();

  gridConstants = new GridConstants();
  matchModeOptions = matchModeOptionsForFilter;
  GRID_DATA_TYPE = GRID_DATA_TYPE;
  BUTTON_TYPE = ButtonType;

  data: any[] = [];
  dataCopy: any[] = [];
  totalRecords = 0;
  totalRecordsInDb = 0;
  sortingDirection: number;
  sortingField: string | undefined;

  pageSize: number;

  constructor(
    private readonly gridService: GridService,
    private readonly filterPipe: FilterTablePipe,
    private readonly commonService: CommonService
  ) {}

  ngOnChanges() {
    if (this.apiConfig?.methodType === METHOD_TYPE.POST) {
      this.pageSize = this.apiConfig.requestBody.pageSize;
    } else if (this.apiConfig?.methodType === METHOD_TYPE.POST1) {
      this.pageSize = this.apiConfig.requestBody.size;
    }
    if (this.commonTable) {
      this.commonTable.reset();
    }
    this.getGridData();
  }

  getGridData() {
    if (this.apiConfig?.methodType === METHOD_TYPE.POST) {
      this.httpPostCall();
    } else if (this.apiConfig?.methodType === METHOD_TYPE.POST1) {
      this.httpPost1Call();
    }
  }

  httpPost1Call() {
    this.data = [];
    const param = new URLSearchParams(this.apiConfig.requestBody);
    const url = `${this.apiConfig.url}?${param.toString()}`;
    this.gridService.getGridData1(url, {}).subscribe({
      next: (res: any) => {
        if (res.content?.length) {
          const data = res.content;
          if (data?.length) {
            this.data = this.preProcessGridData(data);
          }
          this.dataCopy = [...this.data];
          this.totalRecords = res.totalElements;
          this.totalRecordsInDb = this.totalRecords;
          this.commonTable.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          this.data = [];
          this.dataCopy = [...this.data];
          this.totalRecords = 0;
          this.totalRecordsInDb = this.totalRecords;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.totalRecords = 0;
        this.totalRecordsInDb = 0;
        this.commonService.showError(err);
      },
    });
  }

  httpPostCall() {
    this.data = [];
    const url = this.apiConfig.url;
    this.gridService.getGridData(url, this.apiConfig.requestBody).subscribe({
      next: (res: any) => {
        if (!res.error) {
          const data = res.data.filteredResponse;
          if (data?.length) {
            this.data = this.preProcessGridData(data);
          }
          this.dataCopy = [...this.data];
          this.totalRecords = res.data?.page?.totalRecords ?? 0;
          this.totalRecordsInDb = this.totalRecords;
          this.commonTable.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          this.data = [];
          this.dataCopy = [...this.data];
          this.totalRecords = 0;
          this.totalRecordsInDb = this.totalRecords;
          this.commonService.showErrorMessage(res.errors[0].errorMessage);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.totalRecords = 0;
        this.totalRecordsInDb = 0;
        this.commonService.showError(err);
      },
    });
  }

  preProcessGridData(data: any[]) {
    switch (this.gridName) {
      case GRID_NAME.WABA_LISTING:
        return this.gridService.processWabaListing(data);
      case GRID_NAME.FORM_LISTING:
        return this.gridService.processFormListing(data);
      case GRID_NAME.SEGMENT_LISTING:
        return this.gridService.processSegmentListing(data);
      case GRID_NAME.SCHEDULER_LISTING:
        return this.gridService.processSchedulerListing(data);
      case GRID_NAME.CAMPAIGN_LISTING:
        return this.gridService.processCampaignListing(data);
      case GRID_NAME.FILE_UPLOAD_LISTING:
        return this.gridService.processFileUploadListing(data);
      case GRID_NAME.TEMPLATE_LISTING:
        return this.gridService.processTemplateListing(data);
      default:
        return data;
    }
  }

  onButtonClick(rowData: any, eventName: string) {
    this.onButtonClickEvent.emit({ rowData, eventName });
  }

  onCellClick(rowData: any, header: GridHeader) {
    this.onCellClickEvent.emit({ rowData, header });
  }

  onPagination(event: LazyLoadEvent): void {
    try {
      this.resetFilter();
    } catch (e) {}
    this.pageSize = event.rows!;
    if (this.apiConfig.methodType === METHOD_TYPE.POST) {
      this.apiConfig.requestBody = {
        ...this.apiConfig.requestBody,
        pageNumber: Math.floor(event.first! / event.rows!),
        pageSize: event.rows!,
      };
    } else if (this.apiConfig.methodType === METHOD_TYPE.POST1) {
      this.apiConfig.requestBody = {
        ...this.apiConfig.requestBody,
        page: Math.floor(event.first! / event.rows!),
        size: event.rows!,
      };
    }

    this.getGridData();
  }

  resetFilter() {
    const filters = this.commonTable.filters;
    for (const key of Object.keys(filters)) {
      for (const filterV of filters[key] as any) {
        filterV.value = '';
      }
    }
    this.commonTable.filters = filters;
  }

  onSort(event: SortEvent): void {
    this.sortingField = event.field;
    if (event.order === 1) {
      this.sortingDirection = 1;
    } else {
      this.sortingDirection = -1;
    }
    this.sortData();
  }

  sortData() {
    if (this.sortingField) {
      this.data.sort((a, b) => {
        const value1 = a[this.sortingField as keyof typeof a];
        const value2 = b[this.sortingField as keyof typeof b];
        let result = null;
        if (!value1 || !value2) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (this.isDate(value1) && this.isDate(value2)) {
          const date1 = new Date(value1);
          const date2 = new Date(value2);
          return this.sortingDirection * (date1.getTime() - date2.getTime());
        } else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        return this.sortingDirection * result;
      });
    }
  }

  isDate(value: any): boolean {
    return value instanceof Date || !isNaN(Date.parse(value));
  }

  applyFilter(event: any): void {
    if (this.hasFilter(event.filters)) {
      if (this.data.length === this.dataCopy.length) {
        this.dataCopy = JSON.parse(JSON.stringify(this.data));
      }
      this.data = this.filterPipe.transform(this.dataCopy, event.filters);
      this.totalRecords = this.data.length;
    } else {
      this.data = this.dataCopy;
      this.totalRecords = this.totalRecordsInDb;
    }
  }

  hasFilter(filters: any) {
    let empty = true;
    if (Object.keys(filters).length === 0) {
      return false;
    } else {
      for (const prop in filters) {
        if (filters[prop]) {
          filters[prop].forEach((filterObj: any) => {
            if (filterObj?.value?.length > 0) {
              empty = false;
            }
          });
        }
      }
      return !empty;
    }
  }

  getCurrentTableData() {
    return JSON.parse(JSON.stringify(this.data));
  }

  updateData(data: any[], keyName: string) {
    const dataMap: any = {};
    data.forEach((obj: any) => {
      dataMap[obj[keyName]] = obj;
    });
    const arr = [];
    for (const obj of this.data) {
      if (dataMap[obj[keyName]]) {
        arr.push(dataMap[obj[keyName]]);
      } else {
        arr.push(obj);
      }
    }
    this.data = arr;
  }
}
