import { Component } from '@angular/core';
import Quill from 'quill';
import quillEmoji from 'quill-emoji/dist/quill-emoji';
@Component({
  selector: 'app-editor-template',
  templateUrl: './editor-template.component.html',
  styleUrls: ['./editor-template.component.scss'],
})
export class EditorTemplateComponent {
  constructor() {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      'serif',
      'monospace',
      'arial',
      'helvetica',
      'georgia',
      'verdana',
      'calibri',
      'garamond',
      'tahoma',
      'times-new-roman',
    ];
    Quill.register(Font, true);
    Quill.register('modules/emoji', quillEmoji);
  }
}
