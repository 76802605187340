import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ButtonActionType,
  Orientation,
  RCSLayout,
  ButtonActionValue,
} from '../../../modules/templates/interfaces/create-template.interface';
import { EXTENSION, MEDIA_TYPE } from 'src/app/core/constants/common';
import { Icons } from 'src/app/shared/utilities/icons';

@Component({
  selector: 'app-rcs-template-preview',
  templateUrl: './rcs-template-preview.component.html',
  styleUrls: ['./rcs-template-preview.component.scss'],
})
export class RcsTemplatePreviewComponent implements OnChanges {
  @Input() mediaURL?: string;
  @Input() content: string;
  @Input() formData: any = {};
  @Input() ctaButtons?: any[];
  @Input() isCarousel: boolean;
  @Input() carouselCards: any[];
  @Input() isCalledFromCreation = false;

  icons = Icons;
  mediaType = MEDIA_TYPE;
  buttonActionType = ButtonActionType;
  mediaFileType: string;
  maxLetterCount: number;
  carouselUICards: any[];
  orientation = Orientation;
  rcsLayout = RCSLayout;
  pdfFileName: string;
  title = '';
  buttonActionValue = ButtonActionValue;
  isTextExpanded = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mediaURL']?.currentValue) {
      this.mediaURL = changes['mediaURL'].currentValue;
      if (this.mediaURL && !this.mediaURL.includes('#mediaUrl1#')) {
        this.setTypeAndExtension(this.mediaURL);
      }
    }
    if (changes['formData']?.currentValue) {
      this.formData = changes['formData'].currentValue;
      this.modifyVideoThumbnail(this.formData);
      this.maxLetterCount = 270;
    }
    if (changes['ctaButtons']?.currentValue?.length) {
      this.ctaButtons = changes['ctaButtons'].currentValue;
    }
    if (changes['isCarousel']?.currentValue) {
      this.isCarousel = changes['isCarousel'].currentValue;
    }
    if (
      changes['carouselCards']?.currentValue?.length &&
      JSON.stringify(changes['carouselCards'].previousValue) !==
        JSON.stringify(changes['carouselCards']?.currentValue)
    ) {
      this.formData = { ...this.formData, isCarousel: true };
      this.carouselCards = changes['carouselCards'].currentValue;
      this.carouselUICards = [...this.carouselCards];
      if (this.carouselUICards?.length && this.isCalledFromCreation) {
        this.modifyCarouselCards();
      } else if (this.carouselUICards?.length) {
        this.carouselUICards = this.carouselCards.map((card) => {
          return {
            ...card,
            mediaURL: card.mediaURL.includes('#mediaUrl')
              ? `#${card.mediaURL?.split('/#')?.[1]}`
              : card.mediaURL,
            staticUrl: card.mediaURL.includes('#mediaUrl')
              ? card.mediaURL?.split('/#')?.[0]
              : '',
            carouselMediaType: card.mediaType,
            ...(card.videoThumbnail && {
              staticThumbnailUrl: card.videoThumbnail?.includes('#thumbnail')
                ? card.videoThumbnail?.split('/#')?.[0]
                : '',
            }),
            ...(card.videoThumbnail && {
              videoThumbnail: card.videoThumbnail?.includes('#thumbnail')
                ? `#${card.videoThumbnail?.split('/#')?.[1]}`
                : card.videoThumbnail,
            }),
          };
        });
      }
    }
  }

  setTypeAndExtension(mediaURL: string): void {
    const extension = mediaURL.split('.').pop()?.toUpperCase();
    this.mediaFileType =
      extension === EXTENSION.MP4
        ? MEDIA_TYPE.VIDEO
        : extension === EXTENSION.PNG ||
          extension === EXTENSION.JPEG ||
          extension === EXTENSION.JPG ||
          extension === EXTENSION.GIF
        ? MEDIA_TYPE.IMAGE
        : MEDIA_TYPE.DOC;

    if (this.mediaFileType === MEDIA_TYPE.DOC) {
      this.pdfFileName = this.getFileNameFromURL(mediaURL);
    }
  }

  getFileNameFromURL(url: string): string {
    const name = url
      ?.slice(url.lastIndexOf('/') + 1, url.lastIndexOf('_'))
      ?.replaceAll('%20', ' ');
    const arr = url?.split('.');
    const extension = arr[arr.length - 1];
    return `${name}.${extension}`;
  }

  modifyCarouselCards(): void {
    this.carouselUICards = this.carouselCards.map((card) => {
      return {
        ...card,
        mediaURL: card.cardImage || '',
        content: card.cardDesc,
        title: card.cardTitle,
        mediaType: card.cardImage?.includes('.mp4')
          ? MEDIA_TYPE.VIDEO
          : MEDIA_TYPE.IMAGE,
        staticUrl: card.staticUrl,
        videoThumbnail: card.videoThumbnail,
        staticThumbnailUrl: card.staticThumbnailUrl,
        buttons: card.button?.map((cta: any) => {
          return {
            ...cta,
            buttonAction: cta.action?.replace(/_/g, ' '),
          };
        }),
      };
    });
  }

  modifyVideoThumbnail(formData: any): void {
    if (!this.isCalledFromCreation) {
      this.formData = {
        ...formData,
        isButton: formData?.rcsTemplates?.[0]?.buttons?.length ? true : false,
        buttonRequests: formData?.rcsTemplates?.[0]?.buttons,
        ...formData?.rcsTemplates?.[0],
        mediaUrl: formData?.rcsTemplates?.[0]?.mediaURL,
        mediaType: formData?.rcsTemplates?.[0]?.mediaType?.toUpperCase(),
      };
      delete this.formData?.rcsTemplates;
    } else {
      if (this.formData?.videoThumbnail?.includes('#thumbnail1#')) {
        if (this.formData?.staticThumbnailUrl?.length) {
          this.formData = {
            ...formData,
            videoThumbnail: this.formData?.videoThumbnail,
          };
        } else {
          this.formData = {
            ...formData,
            videoThumbnail: '',
          };
        }
      }
    }
  }

  toggleText(): void {
    this.isTextExpanded = !this.isTextExpanded;
  }
}
