import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration.service';
import { FORM_STATUS } from 'src/app/modules/form-design/constants/forms-listing.constants';
import { SegmentStatus } from 'src/app/modules/segments/interfaces/segment-list-response.interface';
import * as moment from 'moment';
import { SchedulerStatus } from 'src/app/modules/campaign-scheduler/interfaces/scheduler-listing-response.interface';
import { CampaignStatus } from 'src/app/modules/campaigns/interfaces/campaign-list-response.interface';
import { ITemplateFilteredData } from 'src/app/modules/templates/interfaces/template-list-response.interface';
import { TemplateStatus } from 'src/app/core/constants/common';
import { SegmentType } from 'src/app/modules/campaign-scheduler/interfaces/schedule-campaign.interface';
import { ChannelType } from 'src/app/modules/templates/interfaces/create-template.interface';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  format = {
    dashDateFormat: 'DD-MM-YYYY',
    spaceDateFormat: 'DD MMM YYYY',
  };

  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigurationService
  ) {}

  getGridData(url: string, payload: any) {
    return this.http.post(url, payload);
  }

  getGridData1(url: string, payload: any) {
    return this.http.post(url, payload);
  }

  processWabaListing(data: any[]) {
    const wabaMasterConfig = this.configService.getWabaMasterMap();
    return this.prePareWabaListData(
      data,
      wabaMasterConfig.bspMap,
      wabaMasterConfig.wabaTypeMap
    );
  }

  prePareWabaListData(
    data: any[],
    bspMap: { [key: string]: string },
    wabaTypeMap: { [key: string]: string | { [key: string]: string } }
  ) {
    const subTypeMap: { [key: string]: string } = wabaTypeMap[
      'wabaSubTypeMap'
    ] as { [key: string]: string };
    for (const obj of data) {
      obj.bspStr = bspMap[obj.bsp] ? bspMap[obj.bsp] : obj.bsp;
      obj.typeStr = wabaTypeMap[obj.type] ? wabaTypeMap[obj.type] : obj.type;
      obj.subTypeStr =
        obj.subType && subTypeMap[obj.subType]
          ? subTypeMap[obj.subType]
          : obj.subType;

      if (obj.statusReason) {
        let tooltipText;
        if (obj.active) {
          tooltipText = `<b>Reason of Reactivation</b> <br> <span> ${obj.statusReason}</span>`;
        } else {
          tooltipText = `<b>Reason of Deactivation</b> <br> <span> ${obj.statusReason}</span>`;
        }
        obj.statusInfo = tooltipText;
      }
      if (obj.active) {
        obj.statusStr = 'Active';
        obj.isTestView = true;
        obj.statusColClasses = ' executed-status ';
        obj.isCopyView = true;
        if (!obj.defaultStatus) {
          obj.isDeactivateView = true;
        }
      } else {
        obj.statusStr = 'Inactive';
        obj.isReactivateView = true;
        obj.statusColClasses = ' failed-text ';
      }
    }
    return data;
  }

  processFormListing(data: any[]) {
    for (const obj of data) {
      if (obj.reason) {
        obj.statusInfo = `<b>Reason of De-Comission</b> <br> <span> ${obj.reason}</span>`;
      }
      obj.isPreviewView = true;
      if (obj.formStatus === FORM_STATUS.DECOMMISIONED) {
        obj.statusColClasses = ' failed-text ';
      } else if (obj.formStatus === FORM_STATUS.PUBLISHED) {
        obj.isDecommissionedView = true;
        obj.isCopyView = true;
        obj.statusColClasses = ' publish-status ';
      } else if (obj.formStatus === FORM_STATUS.DRAFT) {
        obj.isConfigureView = true;
        obj.isDeleteView = true;
        obj.statusColClasses = ' draft-status ';
      } else if (obj.formStatus === FORM_STATUS.INPROGRESS) {
        obj.statusColClasses = ' inprogress-status ';
      }
    }
    return data;
  }

  processSegmentListing(data: any[]) {
    for (const obj of data) {
      switch (obj.status) {
        case SegmentStatus.CREATED:
          obj.statusColClasses = ' created-status ';
          break;
        case SegmentStatus.EXECUTED:
          obj.statusColClasses = ' executed-status ';
          break;
        case SegmentStatus.FAILED:
        case SegmentStatus.SCRUBBING_FAILED:
        case SegmentStatus.EXTRACTION_FAILED:
        case SegmentStatus.CANCELLED:
        case SegmentStatus.INVALID:
          obj.statusColClasses = ' failed-text ';
          break;
        case SegmentStatus.IN_PROGRESS:
        case SegmentStatus.PAUSED:
          obj.statusColClasses = ' inprogress-status ';
          break;
        case SegmentStatus.SCHEDULED:
          obj.statusColClasses = ' scheduled-status ';
          break;
        case SegmentStatus.MAPPED:
          obj.statusColClasses = ' blue-text ';
          break;
        case SegmentStatus.SCRUBBING:
        case SegmentStatus.TRANSFORMING:
        case SegmentStatus.EXTRACTING:
          obj.statusColClasses = ' dark-blue-text ';
          break;
        case SegmentStatus.EXTRACTED:
          obj.statusColClasses = ' green-text ';
          break;
        case SegmentStatus.SCRUBBED:
          obj.statusColClasses = ' light-green-text ';
          break;
        case SegmentStatus.CAMPAIGN_RESCHEDULED:
          obj.statusColClasses = ' orange-text ';
          break;
        default:
          break;
      }
    }
    return data;
  }

  processSchedulerListing(data: any[]) {
    const dateFormat = 'DD/MM/YYYY HH:mm';

    for (const obj of data) {
      obj.isCancelView = false;
      obj.isPauseView = false;
      obj.isResumeView = false;
      obj.isRescheduleView = false;

      if (!obj.frequency) {
        // manual cancel/reschedule
        if (
          [
            SchedulerStatus.SCHEDULED,
            SchedulerStatus.RESCHEDULED,
            SchedulerStatus.RESCHEDULING_FAILED,
            SchedulerStatus.PAUSED,
          ].includes(obj.status) &&
          this.isDateAfterTodayBeforeOneHour(obj.executionStartDate, dateFormat)
        ) {
          obj.isCancelView = true;
          obj.isRescheduleView = true;
        }

        // manual pause
        if (
          [
            SchedulerStatus.SCHEDULED,
            SchedulerStatus.RESCHEDULED,
            SchedulerStatus.RESCHEDULING_FAILED,
          ].includes(obj.status) &&
          this.isDateAfterTodayBeforeOneHour(
            obj.executionStartDate,
            dateFormat
          ) &&
          obj.channel !== 'RCS'
        ) {
          obj.isPauseView = true;
        }

        // manual resume
        if (
          SchedulerStatus.PAUSED === obj.status &&
          this.isDateAfterTodayBeforeOneHour(obj.executionStartDate, dateFormat)
        ) {
          obj.isResumeView = true;
        }
      } else if (obj.segmentType === SegmentType.AUTOMATED && obj.frequency) {
        // recurring cancel/ pause
        if (
          [
            SchedulerStatus.SCHEDULED,
            SchedulerStatus.RESCHEDULED,
            SchedulerStatus.TO_BE_SCHEDULED,
          ].includes(obj.status) &&
          this.isDateAfterTodayBeforeOneHour(obj.executionStartDate, dateFormat)
        ) {
          obj.isCancelView = true;
          obj.isPauseView = true;
        } else if (
          [
            SchedulerStatus.FAILED,
            SchedulerStatus.EXECUTED,
            SchedulerStatus.INVALID_SEGMENT,
            SchedulerStatus.SCHEDULING_FAILED,
            SchedulerStatus.SCHEDULED,
          ].includes(obj.status) &&
          this.isDateSameOrBeforeToday(obj.executionStartDate, dateFormat) &&
          this.isDateSameOrAfterToday(obj.executionEndDate, dateFormat)
        ) {
          const flag = this.isActionBetweenExecutionTime(
            obj.executionStartDate
          );
          obj.isCancelView = flag;
          obj.isPauseView = flag;
        }
        // recurring resume
        if (SchedulerStatus.PAUSED === obj.status) {
          if (
            this.isDateAfterTodayBeforeOneHour(
              obj.executionStartDate,
              dateFormat
            )
          ) {
            obj.isResumeView = true;
            obj.isCancelView = true;
          } else if (
            this.isDateSameOrBeforeToday(obj.executionStartDate, dateFormat) &&
            this.isDateSameOrAfterToday(obj.executionEndDate, dateFormat)
          ) {
            const flag = this.isActionBetweenExecutionTime(
              obj.executionStartDate
            );
            obj.isResumeView = flag;
            obj.isCancelView = flag;
          }
        }

        // recurring reschedule
        if (
          [
            SchedulerStatus.SCHEDULED,
            SchedulerStatus.RESCHEDULED,
            SchedulerStatus.TO_BE_SCHEDULED,
            SchedulerStatus.PAUSED,
          ].includes(obj.status) &&
          this.isDateAfterTodayBeforeOneHour(obj.executionStartDate, dateFormat)
        ) {
          obj.isRescheduleView = true;
        } else if (
          [
            SchedulerStatus.FAILED,
            SchedulerStatus.EXECUTED,
            SchedulerStatus.INVALID_SEGMENT,
            SchedulerStatus.PAUSED,
            SchedulerStatus.SCHEDULING_FAILED,
            SchedulerStatus.SCHEDULED,
          ].includes(obj.status) &&
          this.isDateSameOrBeforeToday(obj.executionStartDate, dateFormat) &&
          this.isDateSameOrAfterToday(obj.executionEndDate, dateFormat)
        ) {
          obj.isRescheduleView = this.isActionBetweenExecutionTime(
            obj.executionStartDate
          );
        }
      }

      if (obj.campaignStartDate) {
        obj.campaignStartDateStr = moment(
          obj.campaignStartDate,
          this.format.dashDateFormat
        ).format(this.format.spaceDateFormat);
      }

      if (obj.campaignEndDate) {
        obj.campaignEndDateStr = moment(
          obj.campaignEndDate,
          this.format.dashDateFormat
        ).format(this.format.spaceDateFormat);
      }

      if (obj.executionStartDate) {
        obj.executionStartDateStr = moment(
          obj.executionStartDate,
          'DD-MM-YYYY HH:mm'
        ).format('DD MMM YYYY HH:mm');
      }

      // if (obj.executionEndDate) {
      //   obj.executionEndDateStr = moment(
      //     obj.executionEndDate,
      //     'DD-MM-YYYY HH:mm'
      //   ).format('DD MMM YYYY HH:mm');
      // }

      if (obj.lastExecutedOn) {
        obj.lastExecutedOnStr = moment(
          obj.lastExecutedOn,
          this.format.dashDateFormat
        ).format(this.format.spaceDateFormat);
      }

      switch (obj.status) {
        case SchedulerStatus.IN_PROGRESS:
        case SchedulerStatus.PAUSED:
        case SchedulerStatus.TO_BE_SCHEDULED:
          obj.statusColClasses = ' inprogress-status ';
          break;
        case SchedulerStatus.EXECUTED:
          obj.statusColClasses = ' executed-status ';
          break;
        case SchedulerStatus.SCHEDULED:
        case SchedulerStatus.RESCHEDULED:
          obj.statusColClasses = ' scheduled-status ';
          break;
        case SchedulerStatus.RESCHEDULING_FAILED:
        case SchedulerStatus.FAILED:
        case SchedulerStatus.CANCELLED:
        case SchedulerStatus.SCHEDULING_FAILED:
        case SchedulerStatus.UNSCHEDULING_FAILED:
        case SchedulerStatus.INVALID_SEGMENT:
          obj.statusColClasses = ' failed-text ';
          break;
        default:
          break;
      }
    }
    return data;
  }

  processCampaignListing(data: any[]) {
    for (const obj of data) {
      if (obj.startDate) {
        obj.startDateStr = moment(
          obj.startDate,
          this.format.dashDateFormat
        ).format(this.format.spaceDateFormat);
      }
      if (obj.endDate) {
        obj.endDateStr = moment(obj.endDate, this.format.dashDateFormat).format(
          this.format.spaceDateFormat
        );
      }
      if (obj.status === CampaignStatus.DRAFT) {
        obj.statusColClasses = ' inprogress-status ';
      } else if (obj.status === CampaignStatus.PUBLISHED) {
        obj.statusColClasses = ' executed-status ';
      }
    }
    return data;
  }

  processFileUploadListing(data: any[]) {
    for (const obj of data) {
      if (obj.communicationChannel === 'WHATSAPP') {
        obj.channel = 'WhatsApp';
        obj.channelIconSrc = 'preview/whatsapp_icon.svg';
      } else if (obj.communicationChannel === 'SMS') {
        obj.channel = 'SMS';
        obj.channelIconSrc = 'preview/sms_icon.svg';
      }
    }
    return data;
  }

  processTemplateListing(filteredResponse: ITemplateFilteredData[]) {
    filteredResponse.forEach((rowData: ITemplateFilteredData) => {
      rowData.isEditView = false;
      rowData.isPreviewView = false;
      rowData.isRetireView = false;
      rowData.isCopyView = false;
      rowData.isReactivateView = false;
      rowData.statusColClasses = '';
      const rowDataStatus = rowData.status?.toLowerCase();
      const rowDataChannel = rowData.channel?.toLowerCase();
      if (rowData?.statusReason) {
        let tooltipText = '';
        if (rowDataStatus === TemplateStatus.RETIRED.toLowerCase()) {
          tooltipText = 'Retiring';
        } else if (rowDataStatus === TemplateStatus.PUBLISHED.toLowerCase()) {
          tooltipText = 'Reinstating';
        }
        tooltipText = `<b>Reason of ${tooltipText} template </b> <br> <span> ${rowData?.statusReason}</span>`;
        rowData.statusInfo = tooltipText;
      }
      if (rowData.emailTemplate) {
        rowData.subject = rowData.emailTemplate.subject;
      }

      if (rowDataStatus === TemplateStatus.PUBLISHED.toLowerCase()) {
        if (
          rowDataChannel?.toLowerCase() ===
            ChannelType.WHATSAPP?.toLowerCase() ||
          rowDataChannel?.toLowerCase() === ChannelType.SMS?.toLowerCase()
        ) {
          rowData.isEditView = true;
        }
        rowData.isPreviewView = true;
        rowData.isRetireView = true;
        rowData.isCopyView = true;
        rowData.statusColClasses = ' executed-status ';
        rowData.isTestView = true;
      } else if (rowDataStatus === TemplateStatus.PENDING.toLowerCase()) {
        rowData.isPreviewView = true;
        rowData.isCopyView = true;
        rowData.statusColClasses = ' inprogress-status ';
      } else if (rowDataStatus === TemplateStatus.DRAFT.toLowerCase()) {
        rowData.isDeleteView = true;
        rowData.isEditView = true;
        rowData.isPreviewView = true;
        rowData.statusColClasses = ' draft-status ';
      } else if (rowDataStatus === TemplateStatus.RETIRED.toLowerCase()) {
        rowData.isCopyView = true;
        rowData.isReactivateView = true;
        rowData.isPreviewView = true;
        rowData.statusColClasses = ' failed-text ';
      } else if (rowDataStatus === TemplateStatus.REJECTED.toLowerCase()) {
        if (rowDataChannel === 'whatsapp') {
          rowData.isEditView = true;
        }
        rowData.isPreviewView = true;
        rowData.isCopyView = true;
        rowData.statusColClasses = ' failed-text ';
      }
    });
    return filteredResponse;
  }

  isDateSameOrAfterToday(dateStr: string, format: string) {
    return moment(dateStr, format).isSameOrAfter(moment());
  }

  isDateSameOrBeforeToday(dateStr: string, format: string) {
    return moment(dateStr, format).isSameOrBefore(moment());
  }

  isDateAfterToday(dateStr: string, format: string) {
    return moment(dateStr, format).isAfter(moment());
  }

  isDateSameAsToday(dateStr: string, format: string) {
    return moment(dateStr, format).isSame(moment());
  }

  isDateAfterTodayBeforeOneHour(dateStr: string, format: string) {
    return moment(dateStr, format).subtract(1, 'hour').isAfter(moment());
  }

  isDateBeforeToday(dateStr: string, format: string) {
    return moment(dateStr, format).isBefore(moment());
  }

  isActionBetweenExecutionTime(startDate: string): boolean {
    let flag = false;
    const startTime = startDate.split(' ')[1];

    if (moment(startTime, 'HH:mm').isAfter(moment())) {
      if (moment(startTime, 'HH:mm').subtract(1, 'hour').isAfter(moment())) {
        flag = true;
      }
    } else {
      flag = true;
    }
    return flag;
  }
}
