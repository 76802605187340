import { GRID_DATA_TYPE } from 'src/app/core/constants/common';

export const SchedulerColumns = {
  campaignCode: {
    header: 'ID',
    type: GRID_DATA_TYPE.TEXT,
    width: '150',
    sort: false,
    filter: false,
    icon: false,
  },
  campaignName: {
    header: 'Name',
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    sort: true,
    filter: false,
    icon: false,
  },
  segmentName: {
    header: 'Target Segment',
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    sort: false,
    filter: false,
    icon: false,
  },
  campaignStartDate: {
    header: 'Campaign Start Date',
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    sort: true,
    filter: false,
    icon: true,
  },
  campaignEndDate: {
    header: 'Campaign End Date',
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    sort: true,
    filter: false,
    icon: true,
  },
  executionStartDate: {
    header: 'Execution Start Date',
    type: GRID_DATA_TYPE.TEXT,
    width: '200',
    sort: true,
    filter: false,
    icon: true,
  },
  status: {
    header: 'Status',
    type: GRID_DATA_TYPE.TEXT,
    width: '150',
    sort: false,
    filter: true,
    icon: true,
  },
};

export const CampaignDetails = [
  {
    label: 'Template Name',
    key: 'primaryTemplateName',
  },
  {
    label: 'Mode of Execution',
    key: 'modeOfExecution',
  },
  {
    label: 'Segment Name',
    key: 'segmentName',
  },
  {
    label: 'Segment Created By',
    key: 'createdBy',
  },
  {
    label: 'Total Records',
    key: 'totalRecords',
  },
  {
    label: 'Total Campaign Runs',
    key: 'campaignRunCount',
  },
  {
    label: 'Channel',
    key: 'primaryChannel',
  },
  {
    label: 'WABA Number',
    key: 'wabaNumber',
  },

  {
    label: 'Web Form Name',
    key: 'primaryWebFormName',
  },
  {
    label: 'Campaign Start Date',
    key: 'campaignStartDate',
  },
  {
    label: 'Campaign End Date',
    key: 'campaignEndDate',
  },
  {
    label: 'Execution Date Time',
    key: 'executionStartDate',
  },
  {
    label: 'Current Status',
    key: 'status',
  },
  {
    label: 'Agent Number',
    key: 'agentNumber',
  },
  {
    label: 'Sender ID',
    key: 'senderId',
  },
];

export const SchedularActionType = {
  CANCEL: 'Cancel',
  PAUSE: 'Pause',
  RESUME: 'Resume',
  RESCHEDULE: 'Reschedule',
};
