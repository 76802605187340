export enum Icons {
  HAMBURGER = '/assets/svg/common/hamburger_menu_icon.svg',
  CMGR_LOGO = '/assets/svg/common/cmgr_logo.svg',
  CMGR_COPYRIGHT_LOGO = '/assets/svg/common/maruti_copyrights_icon.svg',
  NOTIFICATION = '/assets/svg/common/notification_icon.svg',
  OPEN_URL = '/assets/svg/common/open_url.svg',
  CITY_PATTERN = '/assets/svg/dashboard/citypattern.svg',
  CAMPAIGNS = '/assets/svg/dashboard/campaigns.svg',
  TEMPLATES = '/assets/svg/dashboard/templates.svg',
  SEGMENTS = '/assets/svg/dashboard/customersegment.svg',
  CSV = '/assets/svg/extensions/csv_icon.svg',
  XLS = '/assets/svg/extensions/xls_icon.svg',
  PNG = '/assets/svg/extensions/png_icon.svg',
  PDF = '/assets/svg/extensions/pdf_icon.svg',
  MP4 = '/assets/svg/extensions/mp4.svg',
  PDF_GREY = '/assets/svg/extensions/pdf_grey_icon.svg',
  JPG = '/assets/svg/extensions/jpg_icon.svg',
  GIF = '/assets/svg/extensions/gif_icon.svg',
  MSG = '/assets/svg/extensions/outlook_icon.svg',
  UPLOAD_CSV = '/assets/svg/extensions/upload_csv_icon.svg',
  UPLOAD_XLS = '/assets/svg/extensions/upload_xls_icon.svg',
  CALENDER = '/assets/svg/common/calender_icon.svg',
  FILTER = '/assets/svg/common/filter_icon.svg',
  SEARCH = '/assets/svg/common/search.svg',
  CLOSE = '/assets/svg/common/close.svg',
  SMS = '/assets/svg/preview/sms_icon.svg',
  EMAIL = '/assets/svg/preview/email_icon.svg',
  RCS = '/assets/svg/preview/rcs_icon.svg',
  DEFAULT_PREVIEW = '/assets/svg/preview/default_preview.svg',
  WHATSAPP_PREVIEW = '/assets/svg/preview/whatsapp_preview.svg',
  SMS_PREVIEW = '/assets/svg/preview/sms_preview.svg',
  EMAIL_PREVIEW = '/assets/svg/preview/email_preview.svg',
  WHATSAPP = '/assets/svg/preview/whatsapp_icon.svg',
  SENT = '/assets/svg/reports/sent.svg',
  WHATSAPP_GREY = '/assets/svg/reports/whatsapp.svg',
  EMAIL_GREY = '/assets/svg/reports/email.svg',
  SMS_GREY = '/assets/svg/reports/msg.svg',
  DELIVERED = '/assets/svg/reports/delivered.svg',
  REACHED = '/assets/svg/reports/reached.svg',
  RATE = '/assets/svg/reports/rate.svg',
  CLICKS = '/assets/svg/reports/clicks.svg',
  CLICK_RATE = '/assets/svg/reports/clickrate.svg',
  SERVICES = '/assets/svg/reports/services.svg',
  FORMLIST_PREVIEW = '/assets/svg/form-listing/preview.svg',
  FORMLIST_DECOMMISSION = '/assets/svg/form-listing/decommission.svg',
  FORMLIST_EDIT = '/assets/svg/form-listing/edit.svg',
  FORMLIST_DELETE = '/assets/svg/form-listing/delete.svg',
  FORMLIST_COPY_LINK = '/assets/svg/form-listing/copy-form-link.svg',
  REACTIVATE = '/assets/svg/form-listing/reactivate.svg',
  FORMLIST_CONFIGURE_DRAFTS = '/assets/svg/form-listing/configure_drafts.svg',
  DELETE_ICON = '/assets/svg/common/ic_outline-delete.svg',
  REPLY = '/assets/svg/common/reply.svg',
  LQS = '/assets/svg/common/lqs.svg',
  STANDALONE = '/assets/svg/common/standalone.svg',
  PDF_ICON = '/assets/svg/common/pdf_icon.svg',
}
