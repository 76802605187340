import { Component, OnInit } from '@angular/core';
import { loginClient } from 'common-partner-login-sdk/lib/esm';
import { StorageService } from './core/cache/storage.service';
import { Constants } from './shared/utilities/constants';
import { AuthService } from './core/authorization/auth.service';
import { IAPIKeyResponse } from './core/authorization/auth.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from './shared/services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  userData: any = null;

  constructor(
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit() {
    this.getAPIKeys();
  }

  initializeLoginSDK() {
    loginClient.init(
      this.storageService.getKey(Constants.STORAGE_KEYS.AD_API_KEY)
    );
    this.storageService.loginLogout.subscribe((value: string) => {
      this.userData = value === 'login' ? this.getToken() : null;
    });
  }

  getToken() {
    const token = localStorage.getItem(Constants.STORAGE_KEYS.ID_TOKEN);
    return token ? JSON.parse(token) : '';
  }

  getAPIKeys() {
    this.authService.getADAPIKeys().subscribe({
      next: (response: IAPIKeyResponse) => {
        if (response.data) {
          this.storageService.setItem(
            Constants.STORAGE_KEYS.AD_API_KEY,
            response.data.adApiKey
          );
          this.storageService.setItem(
            Constants.STORAGE_KEYS.API_KEY,
            response.data.apiKey
          );
          this.initializeLoginSDK();
        } else {
          this.commonService.showErrorMessage('API Keys not recieved.');
        }
      },
      error: (error: HttpErrorResponse) => {
        this.commonService.showError(error);
      },
    });
  }
}
