import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ICard,
  IPlaceholderO,
  IPlaceholderOrch,
} from 'src/app/modules/segments/interfaces/create-segment.interface';

@Component({
  selector: 'app-placeholder-mapping',
  templateUrl: './placeholder-mapping.component.html',
  styleUrls: ['./placeholder-mapping.component.scss'],
})
export class PlaceholderMappingComponent {
  placeholdersArray: IPlaceholderOrch[];
  // = [{"key":"templatePlaceholderData","header":"Map Template Variables","subHeading":"OwnersManualNew (Channel - WhatsApp)","options":[{"headerName":"﻿recipientId","headerValue":"﻿recipientId"},{"headerName":"Email_ID","headerValue":"Email_ID"},{"headerName":"campaignCode","headerValue":"campaignCode"},{"headerName":"templateCode","headerValue":"templateCode"},{"headerName":"svocId","headerValue":"svocId"},{"headerName":"vin","headerValue":"vin"},{"headerName":"placeholder_1","headerValue":"placeholder_1"}],"placeholderList":[{"label":"Placeholder 1","key":"placeholder1","value":""},{"label":"Placeholder 2","key":"placeholder2","value":""}]},{"key":"ctaData","header":"Map CTA Variables","subHeading":"","options":[{"headerName":"﻿recipientId","headerValue":"﻿recipientId"},{"headerName":"Email_ID","headerValue":"Email_ID"},{"headerName":"campaignCode","headerValue":"campaignCode"},{"headerName":"templateCode","headerValue":"templateCode"},{"headerName":"svocId","headerValue":"svocId"},{"headerName":"vin","headerValue":"vin"},{"headerName":"placeholder_1","headerValue":"placeholder_1"}],"placeholderList":[{"label":"CTA","key":"cta","value":""}]},{"key":"recipientIdData","header":"Select Recipient/Mobile Number","subHeading":"Recipient ID","options":[{"headerName":"﻿recipientId","headerValue":"﻿recipientId"},{"headerName":"Email_ID","headerValue":"Email_ID"},{"headerName":"campaignCode","headerValue":"campaignCode"},{"headerName":"templateCode","headerValue":"templateCode"},{"headerName":"svocId","headerValue":"svocId"},{"headerName":"vin","headerValue":"vin"},{"headerName":"placeholder_1","headerValue":"placeholder_1"}],"placeholderList":[{"label":"RecipientId","key":"recipientId","value":""}]}]

  currentPlaceholderData: IPlaceholderOrch;
  currentIndex = 0;
  isNextSubClicked = false;
  referenceObjMap: any = {};
  urlReferenceObjMap: any = {};

  constructor(
    private readonly config: DynamicDialogConfig,
    public readonly dialogRef: DynamicDialogRef
  ) {
    this.placeholdersArray = this.config.data?.placeholdersArray;
    if (this.placeholdersArray?.length) {
      this.prepareReferenceObjMap();
      this.currentPlaceholderData = this.placeholdersArray[0];
    }
  }

  prepareReferenceObjMap() {
    let i = 0;
    for (let obj of this.placeholdersArray) {
      if (obj.refrenceIndex != null) {
        this.referenceObjMap[obj.refrenceIndex] = i;
      }
      if (obj.urlPropReferenceIndex != null) {
        this.urlReferenceObjMap[obj.urlPropReferenceIndex] = i;
      }
      i++;
    }
  }

  onPrevNextBtnClick(type: string) {
    if (type === 'BACK') {
      this.currentIndex--;
      this.isNextSubClicked = false;
      this.currentPlaceholderData = this.placeholdersArray[this.currentIndex];
    } else if (type === 'NEXT') {
      if (this.checkPlacholderValid()) {
        this.currentIndex++;
        this.isNextSubClicked = false;
        this.currentPlaceholderData = this.placeholdersArray[this.currentIndex];
      } else {
        this.isNextSubClicked = true;
      }
    } else if (type === 'SUBMIT') {
      if (this.checkPlacholderValid()) {
        this.isNextSubClicked = false;
        this.sendPlaceholderData();
      } else {
        this.isNextSubClicked = true;
      }
    }
  }

  checkPlacholderValid() {
    if (this.currentPlaceholderData?.placeholderList) {
      for (let p of this.currentPlaceholderData.placeholderList) {
        if (!p.value) {
          return false;
        }
      }
    }
    if (this.currentPlaceholderData?.cardList) {
      for (
        let index = 0;
        index < this.currentPlaceholderData.cardList.length;
        index++
      ) {
        let card = this.currentPlaceholderData.cardList[index];
        if (card.placeholderList) {
          for (let p of card.placeholderList) {
            if (!p.value) {
              this.collapseCard(index);
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  checkValidPages() {
    let pageMap: any = {};
    this.placeholdersArray.forEach((page: IPlaceholderOrch) => {
      if (page.refrenceIndex == null) {
        pageMap[page.key] = page;
      }
    });
    for (let pageObj of this.placeholdersArray) {
      if (pageObj.placeholderList) {
        for (let placeholder of pageObj.placeholderList) {
          if (!placeholder.value) {
            return false;
          }
        }
      }
    }
    return true;
  }

  copyValueInSameForm(targetIndex: number, sourceIndex: number, key: string) {
    let targetObj = this.placeholdersArray[targetIndex];
    let sourceObj = this.placeholdersArray[sourceIndex];
    if (targetObj && sourceObj) {
      let modifiedObj = sourceObj.placeholderList?.find(
        (obj: IPlaceholderO) => obj.key === key
      ) as IPlaceholderO;
      if (modifiedObj) {
        targetObj.placeholderList?.forEach((obj: IPlaceholderO) => {
          if (obj.key === key) {
            obj.value = modifiedObj.value;
            obj.isDisabled = true;
          }
        });
      }
    }
  }

  onValueSelect(key: string) {
    if (this.referenceObjMap[this.currentIndex]) {
      this.copyValueInSameForm(
        this.referenceObjMap[this.currentIndex],
        this.currentIndex,
        key
      );
    }
    if (this.urlReferenceObjMap[this.currentIndex]) {
      this.copyValueInSameForm(
        this.urlReferenceObjMap[this.currentIndex],
        this.currentIndex,
        key
      );
    }
  }

  onCancelClick() {
    this.dialogRef.close({
      data: this.placeholdersArray,
      isValid: this.checkValidPages(),
    });
  }

  sendPlaceholderData() {
    this.dialogRef.close({
      data: this.placeholdersArray,
      isValid: true,
      isSubmit: true,
    });
  }

  // collapse card
  collapseCard(index: number): void {
    if (this.currentPlaceholderData?.cardList?.length) {
      this.currentPlaceholderData?.cardList.forEach((state, i) => {
        if (i === index) {
          state.isVisible = !state.isVisible;
        } else {
          state.isVisible = false;
        }
      });
    }
  }
}
