import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Icons } from 'src/app/shared/utilities/icons';
import { environment } from 'src/environments/environment';
import { loginClient } from 'common-partner-login-sdk/lib/esm';
import { StorageService } from '../../cache/storage.service';
import { IUserDetails } from 'src/app/shared/interfaces/user-details.interface';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserInactivityService } from 'src/app/shared/services/user-inactivity.service';
import { SIDE_NAV } from '../../constants/common';
import { Constants } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  icons = Icons;
  initials: string;
  userData: IUserDetails | undefined;
  sideMenuList = SIDE_NAV;
  sidebarClass = 'sidebar-collapse';

  constructor(
    private readonly route: Router,
    private readonly storageService: StorageService,
    private readonly commonService: CommonService,
    private readonly userInactivityService: UserInactivityService
  ) {}

  ngOnInit() {
    this.userData = this.commonService.getUserData();
    this.userData &&
      this.getInitials(
        `${this?.userData?.firstName} ${this?.userData?.lastName}`
      );
    this.userInactivityService.userInactive$.subscribe(
      (isInactive: boolean) => {
        if (isInactive) {
          this.logout();
        }
      }
    );
  }

  sidebarToggle() {
    const element = document.getElementsByClassName('wrapper')[0];
    element.classList.toggle(this.sidebarClass);
  }

  autoHideSidebar() {
    const element = document.getElementsByClassName('wrapper')[0];
    if (!element.classList.contains(this.sidebarClass)) {
      element.classList.add(this.sidebarClass);
    }
  }

  logout() {
    this.commonService.logout().subscribe(() => {
      this.storageService.loginLogout.next('logout');
      setTimeout(() => {
        loginClient.federatedLogout(
          this.storageService.getKey(Constants.STORAGE_KEYS.AD_API_KEY),
          environment.LOGOUT_URL
        );
      }, 1000);
      this.storageService.clear();
      this.route.navigate(['login']);
    });
  }

  backToHome() {
    this.route.navigate(['templates']);
    for (const subMenu of this.sideMenuList) {
      subMenu.showChildren = false;
    }
  }

  getInitials(name: string): void {
    const nameArray = name.split(' ');
    const firstNameIn = nameArray[0].charAt(0).toUpperCase();
    const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
    this.initials = firstNameIn + lastNameIn;
  }
}
