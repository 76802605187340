import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Icons } from 'src/app/shared/utilities/icons';
import { SIDE_NAV } from '../../constants/common';
import { AuthConstants } from '../../authorization/auth.constants';
import { ISideNav } from '../../interfaces/side-nav-interfaces';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  icons = Icons;
  currentURL: string;
  sideMenuList = SIDE_NAV;
  permission = AuthConstants;

  constructor(
    private readonly route: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.currentURL = this.route.url.split('/')[1];
    if (this.currentURL?.includes('?')) {
      this.currentURL = this.currentURL.split('?')[0];
    }
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.urlAfterRedirects.split('/')[1];
        if (this.currentURL?.includes('?')) {
          this.currentURL = this.currentURL.split('?')[0];
        }
      }
    });
    if (!this.currentURL) {
      const routeSnapshot: any = this.activatedRoute.snapshot;
      let currentUrl = routeSnapshot._routerState.url;
      if (currentUrl) {
        if (currentUrl.startsWith('/')) {
          currentUrl = currentUrl.slice(1);
        }
        this.currentURL = currentUrl;
        if (this.currentURL?.includes('?')) {
          this.currentURL = this.currentURL.split('?')[0];
        }
      } else {
        this.route.navigateByUrl('/templates');
      }
    } else {
      for (const menu of this.sideMenuList) {
        if (menu.route === this.currentURL && menu?.children) {
          menu.showChildren = true;
        }
      }
    }
  }

  subOptions(
    subMenu: ISideNav,
    show = false,
    hideOtherChildrens = false
  ): void {
    if (!hideOtherChildrens) {
      this.hideAllSubOptions();
    }
    subMenu.showChildren = show;
    this.redirectToSingleChild(subMenu);
  }

  hideAllSubOptions(): void {
    for (const subMenu of this.sideMenuList) {
      subMenu.showChildren = false;
    }
  }

  redirectToSingleChild(subMenu: ISideNav) {
    if (subMenu.children?.length === 1) {
      this.hideAllSubOptions();
      subMenu.showChildren = true;
      this.route.navigate([subMenu.route, subMenu.children[0].route]);
    }
  }
}
